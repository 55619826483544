
import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";
import {
    ResponseDataChart as responseChart,
    FilterFormDefinitive,
    Chart,
    ChartError
} from "@/interfaces";
import * as types from "@/store/types";
import { TOKEN } from "@/services/auth/auth-service";
import { Eoption, noData } from "@/utils";

@Component({
    components: {
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        Echart: () => import("@/components/charts/eChart.vue"),
        FilterForm: () => import("@/pages/components/forms/FilterFormV2.vue"),
        TabItem: () => import("@/components/layout/TabItem.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    }
})
export default class Resume extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;

    filterFormPanelShow: boolean | null = false;
    filterForm: FilterFormDefinitive = {
        company: "",
        client: "",
        installation: "",
        elements: [],
        inversors: [],
        services: [],
        dates: [],
        comparative: false,
        days: "30",
        window: "1dia",
        valid: true
    };
    showFilter = true;
    showAll = false;
    CSVdia = "";
    CSVinstan = "";
    start = "";
    end = "";
    start1 = "";
    start2 = "";
    end1 = "";
    end2 = "";
    first = false;
    energy1Chart: Chart = { data: [] };
    energy2Chart: Chart = { data: [] };
    energy1Stats: any = [];
    energy2Stats: any = [];
    energyCSV1 = "";
    energyCSV2 = "";
    activePower1Chart: Chart = { data: [] };
    activePower2Chart: Chart = { data: [] };
    activePower1Stats: any = [];
    activePower2Stats: any = [];
    activePowerCSV1 = "";
    activePowerCSV2 = "";
    reactivePower1Chart: Chart = { data: [] };
    reactivePower2Chart: Chart = { data: [] };
    reactivePower1Stats: any = [];
    reactivePower2Stats: any = [];
    reactivePowerCSV1 = "";
    reactivePowerCSV2 = "";
    current1Chart: Chart = { data: [] };
    current2Chart: Chart = { data: [] };
    current1Stats: any = [];
    current2Stats: any = [];
    currentCSV1 = "";
    currentCSV2 = "";
    tension1Chart: Chart = { data: [] };
    tension2Chart: Chart = { data: [] };
    tension1Stats: any = [];
    tension2Stats: any = [];
    tensionCSV1 = "";
    tensionCSV2 = "";
    powerFactor1Chart: Chart = { data: [] };
    powerFactor2Chart: Chart = { data: [] };
    powerFactor1Stats: any = [];
    powerFactor2Stats: any = [];
    powerFactorCSV1 = "";
    powerFactorCSV2 = "";
    currentTHD1Chart: Chart = { data: [] };
    currentTHD2Chart: Chart = { data: [] };
    currentTHD1Stats: any = [];
    currentTHD2Stats: any = [];
    currentTHDCSV1 = "";
    currentTHDCSV2 = "";
    tensionTHD1Chart: Chart = { data: [] };
    tensionTHD2Chart: Chart = { data: [] };
    tensionTHD1Stats: any = [];
    tensionTHD2Stats: any = [];
    tensionTHDCSV1 = "";
    tensionTHDCSV2 = "";
    servicios1: any = [];
    servicios2: any = [];
    alarmasTipoDia = { data: [] };
    alarmasFechaElementoDia = { data: [] };
    alarmasFechaTipoDia = { data: [] };
    alarmasElementoTipoDia = { data: [] };
    diarias: any = [];
    // alarmasTipoInstan = { data: [] };
    // alarmasFechaElementoInstan = { data: [] };
    // alarmasFechaTipoInstan = { data: [] };
    // alarmasElementoTipoInstan = { data: [] };
    instantaneas: any = [];
    errors: ChartError[] = [];
    noAlarmDia = true;
    noAlarmInstan = true;
    informe: any = [];
    columnsEnergy = [
        {
            text: this.$t("resumeFast.Elem"),
            sortable: true,
            value: "element"
        },
        {
            text: "Total del Intervalo[kWh]",
            sortable: true,
            value: "tot",
            align: "right"
        }
    ];
    columnsConsumo = [
        {
            text: this.$t("resumeFast.Elem"),
            sortable: true,
            value: "element"
        },
        {
            text: "Total del Intervalo[m³]",
            sortable: true,
            value: "tot",
            align: "right"
        }
    ];
    columnsActivePower = [
        {
            text: this.$t("resumeFast.Elem"),
            sortable: true,
            value: "element"
        },
        {
            text: "Mínimo [kW]",
            sortable: true,
            value: "min",
            align: "right"
        },
        {
            text: "Máximo [kW]",
            sortable: true,
            value: "max",
            align: "right"
        },
        {
            text: "Promedio [kW]",
            sortable: true,
            value: "avg",
            align: "right"
            // },
            // {
            //     text: "Suma [kW]",
            //     sortable: true,
            //     value: "totA",
            //     align: "right"
        }
    ];
    columnsReactivePower = [
        {
            text: this.$t("resumeFast.Elem"),
            sortable: true,
            value: "element"
        },
        {
            text: "Mínimo [kvar]",
            sortable: true,
            value: "min",
            align: "right"
        },
        {
            text: "Máximo [kvar]",
            sortable: true,
            value: "max",
            align: "right"
        },
        {
            text: "Promedio [kvar]",
            sortable: true,
            value: "avg",
            align: "right"
            // },
            // {
            //     text: "Suma [kvar]",
            //     sortable: true,
            //     value: "totR",
            //     align: "right"
        }
    ];
    columnsTension = [
        {
            text: this.$t("resumeFast.Elem"),
            sortable: true,
            value: "element"
        },
        {
            text: "Mínimo [V]",
            sortable: true,
            value: "min",
            align: "right"
        },
        {
            text: "Máximo [V]",
            sortable: true,
            value: "max",
            align: "right"
        },
        {
            text: "Promedio [V]",
            sortable: true,
            value: "avg",
            align: "right"
        }
    ];
    columnsCurrent = [
        {
            text: this.$t("resumeFast.Elem"),
            sortable: true,
            value: "element"
        },
        {
            text: "Mínimo [A]",
            sortable: true,
            value: "min",
            align: "right"
        },
        {
            text: "Máximo [A]",
            sortable: true,
            value: "max",
            align: "right"
        },
        {
            text: "Promedio [A]",
            sortable: true,
            value: "avg",
            align: "right"
        }
    ];
    columnsTable = [
        {
            text: this.$t("resumeFast.Elem"),
            sortable: true,
            value: "element"
        },
        {
            text: "Mínimo",
            sortable: true,
            value: "min",
            align: "right"
        },
        {
            text: "Máximo",
            sortable: true,
            value: "max",
            align: "right"
        },
        {
            text: "Promedio",
            sortable: true,
            value: "avg",
            align: "right"
        }
    ];
    columnsAlarms = [
        {
            text: "Fecha Inicio",
            sortable: true,
            value: "start"
        },
        {
            text: "Fecha Final",
            sortable: true,
            value: "end"
        },
        {
            text: "Instalación",
            sortable: true,
            value: "install"
        },
        {
            text: "Elemento",
            sortable: true,
            value: "element"
        },
        {
            text: "Tipo",
            sortable: true,
            value: "type"
        }
    ];
    columnsInforme = [
        {
            text: "Informe",
            sortable: true,
            value: "nombre"
        },
        {
            text: "Enlace",
            sortable: true,
            value: "link"
        }
    ];

    activated() {
        if (this.errors.length) {
            this.fetchData();
        }
    }
    /**
     * Methods
     */
    async fetchData() {
        this.setProgressBar(true);
        this.showAll = true;
        // this.energy1Chart = { data: [] };
        // this.energy2Chart = { data: [] };
        this.energy1Stats = [];
        this.energy2Stats = [];
        this.energyCSV1 = "";
        this.energyCSV2 = "";
        // this.activePower1Chart = { data: [] };
        // this.activePower2Chart = { data: [] };
        this.activePower1Stats = [];
        this.activePower2Stats = [];
        this.activePowerCSV1 = "";
        this.activePowerCSV2 = "";
        // this.reactivePower1Chart = { data: [] };
        // this.reactivePower2Chart = { data: [] };
        this.reactivePower1Stats = [];
        this.reactivePower2Stats = [];
        this.reactivePowerCSV1 = "";
        this.reactivePowerCSV2 = "";
        // this.current1Chart = { data: [] };
        // this.current2Chart = { data: [] };
        this.current1Stats = [];
        this.current2Stats = [];
        this.currentCSV1 = "";
        this.currentCSV2 = "";
        // this.tension1Chart = { data: [] };
        // this.tension2Chart = { data: [] };
        this.tension1Stats = [];
        this.tension2Stats = [];
        this.tensionCSV1 = "";
        this.tensionCSV2 = "";
        // this.powerFactor1Chart = { data: [] };
        // this.powerFactor2Chart = { data: [] };
        this.powerFactor1Stats = [];
        this.powerFactor2Stats = [];
        this.powerFactorCSV1 = "";
        this.powerFactorCSV2 = "";
        // this.currentTHD1Chart = { data: [] };
        // this.currentTHD2Chart = { data: [] };
        this.currentTHD1Stats = [];
        this.currentTHD2Stats = [];
        this.currentTHDCSV1 = "";
        this.currentTHDCSV2 = "";
        // this.tensionTHD1Chart = { data: [] };
        // this.tensionTHD2Chart = { data: [] };
        this.tensionTHD1Stats = [];
        this.tensionTHD2Stats = [];
        this.tensionTHDCSV1 = "";
        this.tensionTHDCSV2 = "";
        this.servicios1 = [];
        this.servicios2 = [];
        // this.alarmasTipoDia = { data: [] };
        // this.alarmasFechaElementoDia = { data: [] };
        // this.alarmasFechaTipoDia = { data: [] };
        // this.alarmasElementoTipoDia = { data: [] };
        this.diarias = [];
        // this.alarmasTipoInstan = { data: [] };
        // this.alarmasFechaElementoInstan = { data: [] };
        // this.alarmasFechaTipoInstan = { data: [] };
        // this.alarmasElementoTipoInstan = { data: [] };
        this.instantaneas = [];
        this.noAlarmDia = true;
        this.noAlarmInstan = true;
        this.informe = [];
        this.first = false;

        if (this.filterForm.dates) {
            if (this.filterForm.comparative && this.filterForm.days) {
                this.end1 = new Date(
                    new Date(this.filterForm.dates[0]).setDate(
                        new Date(this.filterForm.dates[0]).getDate() +
                            Number(this.filterForm.days)
                    )
                )
                    .toISOString()
                    .split("T")[0];
                this.end2 = new Date(
                    new Date(this.filterForm.dates[1]).setDate(
                        new Date(this.filterForm.dates[1]).getDate() +
                            Number(this.filterForm.days)
                    )
                )
                    .toISOString()
                    .split("T")[0];
                this.start1 = new Date(this.filterForm.dates[0])
                    .toISOString()
                    .split("T")[0];
                this.start2 = new Date(this.filterForm.dates[1])
                    .toISOString()
                    .split("T")[0];
                this.end = this.end2;
                this.start = this.start2;
            } else if (!this.filterForm.comparative && this.filterForm.days) {
                this.end = new Date().toISOString().split("T")[0];
                this.start = new Date(
                    new Date().setDate(
                        new Date().getDate() - Number(this.filterForm.days) + 1
                    )
                )
                    .toISOString()
                    .split("T")[0];
                this.filterForm.dates = [this.start, this.end];
            } else {
                const aux0 = new Date(this.filterForm.dates[0]);
                let aux1;
                if (this.filterForm.dates.length > 1) {
                    aux1 = new Date(this.filterForm.dates[1]);
                    if (aux1 > aux0) {
                        this.end = this.filterForm.dates[1];
                        this.start = this.filterForm.dates[0];
                    } else {
                        this.end = this.filterForm.dates[0];
                        this.start = this.filterForm.dates[1];
                    }
                } else {
                    this.start = this.filterForm.dates[0];
                    this.end = this.start;
                }
            }
        }

        let JSOON: any[] = [];
        if (this.filterForm.inversors) {
            if (this.filterForm.inversors.length) {
                JSOON = [
                    {
                        elements: this.filterForm.elements,
                        inversors: this.filterForm.inversors
                    }
                ];
            } else {
                JSOON = [{ elements: this.filterForm.elements, inversors: [] }];
            }
        } else {
            JSOON = [{ elements: this.filterForm.elements, inversors: [] }];
        }

        do {
            try {
                const informeRes = await this.$api.recapInformes<responseChart>(
                    this.start,
                    this.end,
                    JSOON
                );
                if (informeRes.ok) {
                    this.informe = informeRes.data;
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
            try {
                const consumRes = await this.$api.recapEnergy<responseChart>(
                    this.start,
                    this.end,
                    this.filterForm.comparative,
                    JSOON,
                    true
                );
                if (consumRes.ok) {
                    if (
                        (this.filterForm.comparative && !this.first) ||
                        !this.filterForm.comparative
                    ) {
                        this.energy1Chart = {
                            ...Eoption,
                            ...consumRes.data["figEnr"]
                        };
                        this.energy1Stats = consumRes.data["Enrstat"];
                        this.energyCSV1 = consumRes.data["csv"];
                    } else {
                        this.energy2Chart = {
                            ...Eoption,
                            ...consumRes.data["figEnr"]
                        };
                        this.energy2Stats = consumRes.data["Enrstat"];
                        this.energyCSV2 = consumRes.data["csv"];
                    }
                }
            } catch (error) {
                this.energy1Chart = { data: [] };
                this.energy2Chart = { data: [] };
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
            if (this.filterForm.services) {
                if (this.filterForm.services.length > 0) {
                    try {
                        const serviceRes = await this.$api.recapServices<
                            responseChart
                        >(
                            this.start,
                            this.end,
                            this.filterForm.comparative,
                            this.filterForm.services
                        );
                        if (serviceRes.ok) {
                            if (
                                (this.filterForm.comparative && !this.first) ||
                                !this.filterForm.comparative
                            ) {
                                this.servicios1 = serviceRes.data;
                            } else {
                                this.servicios2 = serviceRes.data;
                            }
                        }
                    } catch (error) {
                        if (error instanceof Error) {
                            this.showError(error.message);
                        }
                    }
                }
            }

            try {
                const powARes = await this.$api.recap<responseChart>(
                    this.start,
                    this.end,
                    this.filterForm.comparative,
                    "PowerA_TOT",
                    JSOON,
                    true
                );
                if (powARes.ok) {
                    if (
                        (this.filterForm.comparative && !this.first) ||
                        !this.filterForm.comparative
                    ) {
                        this.activePower1Chart = {
                            ...Eoption,
                            ...powARes.data["figPowA"]
                        };
                        this.activePower1Stats = powARes.data["powAstat"];
                        this.activePowerCSV1 = powARes.data["csv"];
                    } else {
                        this.activePower2Chart = {
                            ...Eoption,
                            ...powARes.data["figPowA"]
                        };
                        this.activePower2Stats = powARes.data["powAstat"];
                        this.activePowerCSV2 = powARes.data["csv"];
                    }
                }
            } catch (error) {
                this.activePower1Chart = { data: [] };
                this.activePower2Chart = { data: [] };
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
            try {
                const powRRes = await this.$api.recap<responseChart>(
                    this.start,
                    this.end,
                    this.filterForm.comparative,
                    "PowerR_TOT",
                    JSOON,
                    true
                );
                if (powRRes.ok) {
                    if (
                        (this.filterForm.comparative && !this.first) ||
                        !this.filterForm.comparative
                    ) {
                        this.reactivePower1Chart = {
                            ...Eoption,
                            ...powRRes.data["figPowR"]
                        };
                        this.reactivePower1Stats = powRRes.data["powRstat"];
                        this.reactivePowerCSV1 = powRRes.data["csv"];
                    } else {
                        this.reactivePower2Chart = {
                            ...Eoption,
                            ...powRRes.data["figPowR"]
                        };
                        this.reactivePower2Stats = powRRes.data["powRstat"];
                        this.reactivePowerCSV2 = powRRes.data["csv"];
                    }
                }
            } catch (error) {
                this.reactivePower1Chart = { data: [] };
                this.reactivePower2Chart = { data: [] };
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
            try {
                const tensionRes = await this.$api.recap<responseChart>(
                    this.start,
                    this.end,
                    this.filterForm.comparative,
                    "V_avg",
                    JSOON,
                    true
                );
                if (tensionRes.ok) {
                    if (
                        (this.filterForm.comparative && !this.first) ||
                        !this.filterForm.comparative
                    ) {
                        this.tension1Chart = {
                            ...Eoption,
                            ...tensionRes.data["figVol"]
                        };
                        this.tension1Stats = tensionRes.data["Volstat"];
                        this.tensionCSV1 = tensionRes.data["csv"];
                    } else {
                        this.tension2Chart = {
                            ...Eoption,
                            ...tensionRes.data["figVol"]
                        };
                        this.tension2Stats = tensionRes.data["Volstat"];
                        this.tensionCSV2 = tensionRes.data["csv"];
                    }
                }
            } catch (error) {
                this.tension1Chart = { data: [] };
                this.tension2Chart = { data: [] };
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
            try {
                const currentRes = await this.$api.recap<responseChart>(
                    this.start,
                    this.end,
                    this.filterForm.comparative,
                    "Current_avg",
                    JSOON,
                    true
                );
                if (currentRes.ok) {
                    if (
                        (this.filterForm.comparative && !this.first) ||
                        !this.filterForm.comparative
                    ) {
                        this.current1Chart = {
                            ...Eoption,
                            ...currentRes.data["figCurr"]
                        };
                        this.current1Stats = currentRes.data["Currstat"];
                        this.currentCSV1 = currentRes.data["csv"];
                    } else {
                        this.current2Chart = {
                            ...Eoption,
                            ...currentRes.data["figCurr"]
                        };
                        this.current2Stats = currentRes.data["Currstat"];
                        this.currentCSV2 = currentRes.data["csv"];
                    }
                }
            } catch (error) {
                this.current1Chart = { data: [] };
                this.current2Chart = { data: [] };
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
            try {
                const powerFactorRes = await this.$api.recap<responseChart>(
                    this.start,
                    this.end,
                    this.filterForm.comparative,
                    "PF_TOT",
                    JSOON,
                    true
                );
                if (powerFactorRes.ok) {
                    if (
                        (this.filterForm.comparative && !this.first) ||
                        !this.filterForm.comparative
                    ) {
                        this.powerFactor1Chart = this.powerFactor1Chart = {
                            ...Eoption,
                            ...powerFactorRes.data["figPF"]
                        };
                        this.powerFactor1Stats = powerFactorRes.data["PFstat"];
                        this.powerFactorCSV1 = powerFactorRes.data["csv"];
                    } else {
                        this.powerFactor2Chart = this.powerFactor1Chart = {
                            ...Eoption,
                            ...powerFactorRes.data["figPF"]
                        };
                        this.powerFactor2Stats = powerFactorRes.data["PFstat"];
                        this.powerFactorCSV2 = powerFactorRes.data["csv"];
                    }
                }
            } catch (error) {
                this.powerFactor1Chart = { data: [] };
                this.powerFactor2Chart = { data: [] };
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
            try {
                const tensionTHDRes = await this.$api.recap<responseChart>(
                    this.start,
                    this.end,
                    this.filterForm.comparative,
                    "Voltage_THD_avg",
                    JSOON,
                    true
                );
                if (tensionTHDRes.ok) {
                    if (
                        (this.filterForm.comparative && !this.first) ||
                        !this.filterForm.comparative
                    ) {
                        this.tensionTHD1Chart = tensionTHDRes.data["figVolTHD"];
                        this.tensionTHD1Stats =
                            tensionTHDRes.data["VolTHDstat"];
                        this.tensionTHDCSV1 = tensionTHDRes.data["csv"];
                    } else {
                        this.tensionTHD2Chart = {
                            ...Eoption,
                            ...tensionTHDRes.data["figVolTHD"]
                        };
                        this.tensionTHD2Stats =
                            tensionTHDRes.data["VolTHDstat"];
                        this.tensionTHDCSV2 = tensionTHDRes.data["csv"];
                    }
                }
            } catch (error) {
                this.tensionTHD1Chart = { data: [] };
                this.tensionTHD2Chart = { data: [] };
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
            try {
                const currentTHDRes = await this.$api.recap<responseChart>(
                    this.start,
                    this.end,
                    this.filterForm.comparative,
                    "Current_THD_avg",
                    JSOON,
                    true
                );
                if (currentTHDRes.ok) {
                    if (
                        (this.filterForm.comparative && !this.first) ||
                        !this.filterForm.comparative
                    ) {
                        this.currentTHD1Chart = {
                            ...Eoption,
                            ...currentTHDRes.data["figCurrTHD"]
                        };
                        this.currentTHD1Stats =
                            currentTHDRes.data["CurrTHDstat"];
                        this.currentTHDCSV1 = currentTHDRes.data["csv"];
                    } else {
                        this.currentTHD2Chart = {
                            ...Eoption,
                            ...currentTHDRes.data["figCurrTHD"]
                        };
                        this.currentTHD2Stats =
                            currentTHDRes.data["CurrTHDstat"];
                        this.currentTHDCSV2 = currentTHDRes.data["csv"];
                    }
                }
            } catch (error) {
                this.currentTHD1Chart = { data: [] };
                this.currentTHD2Chart = { data: [] };
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
            if (!this.filterForm.comparative) {
                try {
                    const alarmDRes = await this.$api.recapAlarmDiarias<
                        responseChart
                    >(this.start, this.end, JSOON);
                    if (alarmDRes.ok) {
                        this.noAlarmDia = alarmDRes.data["noAlarm_dia"];
                        if (!this.noAlarmDia) {
                            this.diarias = alarmDRes.data["diarias"];
                            this.alarmasTipoDia = {
                                ...Eoption,
                                ...alarmDRes.data["fig_tip_dia"]
                            };
                            this.alarmasFechaElementoDia = {
                                ...Eoption,
                                ...alarmDRes.data["fig_fe_ele_dia"]
                            };
                            this.alarmasFechaTipoDia = {
                                ...Eoption,
                                ...alarmDRes.data["fig_fe_tip_dia"]
                            };
                            this.alarmasElementoTipoDia = {
                                ...Eoption,
                                ...alarmDRes.data["fig_ele_tip_dia"]
                            };
                            this.CSVdia = alarmDRes.data["CSV_dia"];
                        }
                    }
                } catch (error) {
                    this.alarmasTipoDia = { data: [] };
                    this.alarmasFechaElementoDia = { data: [] };
                    this.alarmasFechaTipoDia = { data: [] };
                    this.alarmasElementoTipoDia = { data: [] };
                    if (error instanceof Error) {
                        this.showError(error.message);
                    }
                }
                try {
                    const alarmIRes = await this.$api.recapAlarmInstant<
                        responseChart
                    >(this.start, this.end, JSOON);
                    if (alarmIRes.ok) {
                        this.noAlarmInstan = alarmIRes.data["noAlarm_instan"];
                        if (!this.noAlarmInstan) {
                            this.instantaneas = alarmIRes.data["instantaneas"];
                            // this.alarmasTipoInstan =
                            //     alarmIRes.data["fig_tip_instan"];
                            // this.alarmasFechaElementoInstan =
                            //     alarmIRes.data["fig_fe_ele_instan"];
                            // this.alarmasFechaTipoInstan =
                            //     alarmIRes.data["fig_fe_tip_instan"];
                            // this.alarmasElementoTipoInstan =
                            //     alarmIRes.data["fig_ele_tip_instan"];
                            this.CSVinstan = alarmIRes.data["CSV_instan"];
                        }
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        this.showError(error.message);
                    }
                }
            }

            if (this.filterForm.comparative && this.first) {
                this.first = false;
            } else if (this.filterForm.comparative) {
                this.start = this.start1;
                this.end = this.end1;
                this.first = true;
            }
        } while (this.first);
        this.setProgressBar(false);
    }

    onFilterUpdated() {
        if (Vue.$cookies.isKey(TOKEN)) {
            this.fetchData();
        } else {
            this.$router.push("/signin");
        }
    }

    formatDates() {
        const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric"
        };
        const timezone = String(this.$t("timeZone"));
        if (this.filterForm.comparative) {
            if (this.filterForm?.dates[0] && this.filterForm?.dates[1])
                return (
                    new Intl.DateTimeFormat(timezone, options).format(
                        new Date(this.start2)
                    ) +
                    " - " +
                    new Intl.DateTimeFormat(timezone, options).format(
                        new Date(this.end2)
                    )
                );
        } else {
            if (this.filterForm?.dates[0] && this.filterForm?.dates[1])
                return (
                    new Intl.DateTimeFormat(timezone, options).format(
                        new Date(this.filterForm?.dates[0])
                    ) +
                    " - " +
                    new Intl.DateTimeFormat(timezone, options).format(
                        new Date(this.filterForm.dates[1])
                    )
                );
        }
        return "";
    }

    formatDatesComp() {
        // const options = {
        //     weekday: "long",
        //     year: "numeric",
        //     month: "long",
        //     day: "numeric"
        // };
        const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric"
        };
        const timezone = String(this.$t("timeZone"));
        if (this.filterForm.comparative) {
            if (this.filterForm?.dates[0] && this.filterForm?.dates[1])
                return (
                    new Intl.DateTimeFormat(timezone, options).format(
                        new Date(this.start1)
                    ) +
                    " - " +
                    new Intl.DateTimeFormat(timezone, options).format(
                        new Date(this.end1)
                    )
                );
        }
        return "";
    }

    checkChartErrors(chart: string) {
        const error = this.errors.filter(error => error.chart === chart);
        if (error.length && error[0].chart === chart) {
            this.showError(error[0].message);
        }
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    resetCSV() {
        this.CSVdia = "";
        this.CSVinstan = "";
    }

    get isRowFullscreen() {
        return !!document.fullscreenElement;
    }

    toggleRowFullscreen(rowElement: HTMLElement) {
        if (rowElement) {
            if (!document.fullscreenElement) {
                rowElement.requestFullscreen().catch(err => {
                    console.error(
                        `Error attempting to enable full-screen mode: ${err.message}`
                    );
                });
            } else {
                document.exitFullscreen();
            }
        }
    }

    downloadCSVdia() {
        const anchor = document.createElement("a");
        anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(this.CSVdia);
        anchor.target = "_blank";
        anchor.download =
            this.filterForm.client.split(" - ")[1] +
            "_AlarmasDiarias_" +
            this.start +
            "_" +
            this.end +
            ".csv";
        anchor.click();
    }

    downloadCSVinstan() {
        const anchor = document.createElement("a");
        anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(this.CSVinstan);
        anchor.target = "_blank";
        anchor.download =
            this.filterForm.client.split(" - ")[1] +
            "_AlarmasInstantaneas_" +
            this.start +
            "_" +
            this.end +
            ".csv";
        anchor.click();
    }

    getEchart(option) {
        if (option.dataset) {
            if (option.dataset.source) {
                if (option.dataset.source.length > 0) {
                    return { ...Eoption, ...option };
                }
            }
        }
        return noData;
    }
}
